import React from "react";
// import { UserAuth } from "../context/userContext";
import { Link } from "react-router-dom";
// import Layout from '../components/layout';
import { Box, Button, Typography, Divider } from "@mui/material";
// import { alpha } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { grey } from "@mui/material/colors";
// import theme from "../theme/theme";
// import { httpsCallable } from "firebase/functions";
// import { db, payments, functions } from "../firebase/index";

export default function StockBlock() {

    const theme = useTheme()
    // const { user } = UserAuth()

    return(
        <Box sx={{ 
            display: 'flex', 
            // flexDirection: {xs: 'column', sm: 'row'},
            alignItems: 'center', 
            justifyContent: 'center',
            width: '90%',
            border: 0.5,
            borderColor: theme.palette.border.main,
            borderRadius: '0.4rem',
            background: 'white',
            mt: '2rem',
            }}>
            <Box sx={{
                width: '100%',
                // background: 'red', 
                mt: '1rem',
                mb: '1rem',
                }}>
                <Typography 
                    textAlign={{
                        xs: 'center', 
                        sm: 'left'}}  
                    sx={{
                        ml: {sm: '5%'},
                        width: '100%',
                        fontSize: {xs: 20, sm: 26}, 
                        fontWeight: 500,
                        color: grey[700] 
                        }}>
                    Stock Opportunities
                </Typography>
            </Box>
            <Divider flexItem variant='middle' orientation='vertical' />
            <Box sx={{
                display: 'flex',
                width: '45%',
                alignItems: 'center',
                justifyContent: 'center',
                mt: '1rem',
                mb: '1rem',
                }}>
                <Button 
                    component={Link} 
                    to="/stocks" 
                    variant='contained' 
                    disableElevation 
                    sx={{mt: '1rem', mb: '1rem', width: '55%'}} 
                    >
                    Check
                </Button>
            </Box>
            {/* <Box sx={{display: 'flex', width: {xs: '100%', sm: '75%'}, alignItems: 'center', justifyContent: 'center'}}>2</Box> */}
            {/*  */}
        </Box>
    )
}